const introText = {
  title: {
    true: "Get in touch with us",
    false: "Contactez nous ici",
  },
  p: {
    true: "Get in touch with us",
    false: "Un regards sur",
  },
  point1: {
    true: "Get in touch with us",
    false: "Un regards sur",
  },
  point2: {
    true: "Get in touch with us",
    false: "Un regards sur",
  },
  point3: {
    true: "Get in touch with us",
    false: "Un regards sur",
  },
  
  
};

export default introText;